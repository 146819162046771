




















































































































































































































.courseUser{
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  >.cell {
    text-align: left;
  }
}
.el-table th.is-right {
  >.cell {
    text-align: right;
  }
}
